import React, { useMemo } from 'react';
import styled from 'styled-components';
import Head from 'next/head';
import { useTranslation } from 'react-i18next';
import { ellipsis } from '../styles/ellipsis';

const MainTitleContainer = styled.div`
	margin-bottom: 45px;
`;

const MainTitleHeading = styled.h1`
	font-size: 45px;
	line-height: 50px;
	height: 48px;
	color: #eee;
	margin: 0;
	padding: 0;
	${ellipsis};
`;

const MainTitleColor = styled.div`
	height: 40px;
	width: 16px;
	float: left;
	margin-top: 5px;
	margin-right: 21px;
	background-color: ${(props) => props.color};
`;

type MainTitleProps = {
	title: string;
	color?: string;
};

const MainTitle: React.FC<MainTitleProps> = ({ title, color }) => {
	const { t } = useTranslation();

	const colorComponent = useMemo(() => {
		if (!color) return null;

		return <MainTitleColor color={color} />;
	}, [color]);

	if (!title) return null;

	return (
		<>
			<Head>
				<title>{t('Main.Subtitle', { title })}</title>
				<meta property={'og:title'} content={title} />
			</Head>
			<MainTitleContainer>
				{colorComponent}
				<MainTitleHeading>{title}</MainTitleHeading>
			</MainTitleContainer>
		</>
	);
};

export default MainTitle;
