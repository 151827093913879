import React from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import MainTitle from '../components/MainTitle';
import { PRIMARY } from '../styles/colors';
import { HeaderGap } from '../components/HeaderGap/HeaderGap.styles';

const NotFoundDescription = styled.div`
	font-size: 22px;
`;

export default function NotFoundScene() {
	const { t } = useTranslation();

	return (
		<HeaderGap>
			<div>
				<MainTitle title={t('NotFound.Title')} color={PRIMARY} />
				<NotFoundDescription>
					<p>{t('NotFound.Message')}</p>
					<p>
						<Link href={'/'}>{t('NotFound.Link')}</Link>
					</p>
				</NotFoundDescription>
			</div>
		</HeaderGap>
	);
}
